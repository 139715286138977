<template>
  <div>
    <div class="af-header-mobile px-5 py-5">
      <router-link
        tag="img"
        class="instacash-logo cursor-pointer"
        :src="
          isBrand(CUSTOM_APPEARANCE.MBH)
            ? require('@/assets/images/mbh-logo.svg')
            : require('@/assets/images/instacash-logo-white.svg')
        "
        to="/home"
      />

      <div @click="toggleHamburger">
        <b-icon
          class=""
          icon-pack="fas"
          icon="bars"
          type="is-white"
          size="is-medium"
        ></b-icon>
      </div>
    </div>

    <transition name="slide">
      <div v-if="isHamburgerOpen" class="hamburger">
        <menu-content @click="toggleHamburger"></menu-content>
      </div>
    </transition>
  </div>
</template>

<script>
import { isBrand } from "@/utils/util";
import { CUSTOM_APPEARANCE } from "@/utils/const";

export default {
  name: "UserHeaderMobile",
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
  },
  components: {
    MenuContent: () => import("@/components/MenuContent.vue"),
  },
  data() {
    return {
      isHamburgerOpen: false,
    };
  },
  methods: {
    isBrand,
    toggleHamburger() {
      this.isHamburgerOpen = !this.isHamburgerOpen;
      if (this.isHamburgerOpen) {
        document.body.classList.add("hamburger-visible");
      } else {
        document.body.classList.remove("hamburger-visible");
      }
    },
  },
};
</script>

<style>
.hamburger-visible {
  position: fixed;
}
</style>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.af-header-mobile {
  display: flex;
  height: 70px;
  width: 100%;
  background: $grey-dark;
  align-items: center;
  justify-content: space-between;

  .instacash-logo {
    height: 28px;
  }
}

::v-deep .menu-list {
  margin-left: 0;
  line-height: 3;
}

::v-deep .menu-item {
  margin-bottom: 0;
}

::v-deep .menu-icon {
  margin-left: 1rem;
}

::v-deep a.router-link-active {
  background: #0f2542 !important;
  color: $white;
  box-shadow: inset 4px 0 0 $primary;
}

.hamburger {
  position: absolute;
  z-index: 10;
  height: calc(100vh - 70px);
  top: 70px;
  left: 0;
  width: 100%;
  background: $grey-dark;
}

.slide-enter-active {
  transition-timing-function: ease-out;
  transition-duration: 0.2s;
  max-height: calc(100vh - 70px);
}
.slide-leave-active {
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
  max-height: calc(100vh - 70px);
}

.slide-enter,
.slide-leave-to {
  max-height: 0;
}

@media only screen and (min-width: 769px) {
  .af-header-mobile {
    display: none;
  }

  .hamburger {
    display: none !important;
    height: 0 !important;
  }
}

.mbh {
  .af-header-mobile {
    background: white;
  }

  .has-text-white {
    color: #424242 !important;
  }

  .hamburger {
    background: white;
  }
}
</style>
